const idPortalApi = "5f7e41eec831fd965f1a5ff9517d65b1f8189f8c";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.cmsaodomingos.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsaodomingos.ma.gov.br",
  NomePortal: "São Domingos do Maranhão",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.youtube.com/@camaramunicipalsaodomingosdoma`,
  link_localize: `https://maps.app.goo.gl/LDNQCtQdykYzHixWA`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31767.262489521025!2d-44.392954!3d-5.5806446!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x788801caa8280f7%3A0x496797b96dc8af79!2sS%C3%A3o%20Domingos%20do%20Maranh%C3%A3o%2C%20MA%2C%2065790-000!5e0!3m2!1spt-BR!2sbr!4v1722128261116!5m2!1spt-BR!2sbr",
};
